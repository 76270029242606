<template>
    <div class="wizard-section d-lg-none bg-white">
      <div class="container">
        <div
          class="wizard-navigation d-flex health-wizard justify-content-center wizard-navigation-health"
        >
          <div
            :class="`wizard-navigation_item ${current.vehicle} position-relative`"
            @click="go('Home', current.vehicle)"
            style="cursor: pointer"
          >
            <div class="wizard-navigation_item--number">1</div>
            <div class="wizard-navigation_item--title">
                <p v-if="language === 'en'">{{ $t('bike') }} <br />
                    {{ $t('details') }}</p>
                <p v-if="language === 'ar'">{{ $t('details') }}  <br />
                    {{ $t('bike') }}</p>
            </div>
          </div>
      
          <div
            :class="`wizard-navigation_item ${current.compare} position-relative`"
            @click="go('QoutesDetails', current.compare)"
            style="cursor: pointer"
          >
            <div class="wizard-navigation_item--number">2</div>
            <div class="wizard-navigation_item--title">
              <p v-if="language === 'en'">{{ $t('comp') }} <br />
                    {{ $t('quotes') }}</p>
                <p v-if="language === 'ar'">{{ $t('quotes_header') }} <br />
                    {{ $t('comp_header') }}</p>
            </div>
          </div>
          <div
            :class="` wizard-navigation_item ${current.cover} position-relative`"
            @click="go('SecureChecout', current.cover)"
            style="cursor: pointer"
          >
            <div class="wizard-navigation_item--number">
              <span class="icon-Tickmark"></span>
            </div>
            <div class="wizard-navigation_item--title">
              <p v-if="language === 'en'">{{ $t('your') }} <br />
                    {{ $t('cov') }}</p>
                <p v-if="language === 'ar'">{{ $t('your_cover') }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { store } from "@/store";
import router from '@/router';
import Manufacturers from '@/core/services/car/Manufacturers';
import setVehicle from "@/core/services/car/Vehicles";
import Models from '@/core/services/car/Models';
import TrimLevels from '@/core/services/car/TrimLevels';
import EstimatedValues from '@/core/services/car/EstimatedValues';

export default defineComponent({
    setup() {
        const current = computed(() => {
            return store.state.steppers.stepper;
        });

        const vd = computed(() => {
            return store.state.vehicles.vehicle;
        })

        const go = async(val, y) => {
            let uuid = window.localStorage.getItem(uid(val));
            let source = window.localStorage.getItem('sources')
            const customer_detatils = JSON.parse(window.localStorage.getItem('customer_detatils'))

           let url = '/bike-details'
            switch(val) {
                case 'BikeQuotesDetails':
                    url = '/bike-result-listing' 
                    break
                case 'BikeSecureChecout':
                    url = '/bike-secure-checkout' 
                    break
                default:
                    url = customer_detatils && customer_detatils.customer_source_url ? customer_detatils.customer_source_url : `${process.env.VUE_APP_FOLDER}/bike-details`
                    break;
            }

            url = (process.env.VUE_APP_FOLDER !== 'undefined') ? `/${process.env.VUE_APP_FOLDER}`+url : url;
            vd.value.is_wizard = true
            
            if(y) {
                if(customer_detatils && url !== customer_detatils.customer_source_url) {
                   window.location.href = url
                }
                if(uuid) {
                    if(router.currentRoute.value.name === 'BikeDetails') {
                        return window.location.href = customer_detatils.customer_source_url
                    } else {
                        const passVal = url.includes('?') ? '&' : '?'

                        let path = url
                        if(!url.includes('uuid')) path = `${url}${passVal}uuid=${uuid}`

                        if(source) path += `${source}`
                        if(customer_detatils && customer_detatils.sid && !url.includes('sid')) path += `&sid=${customer_detatils.sid}`
                        
                        return window.location.href = path
                    }
                } else {
                    let urli = url
                    if(source) urli = `${url}?${source}`
                    if(!urli.includes('sid')) {
                        const passVal = urli.includes('?') ? '&' : '?'
                        urli = `${urli}${passVal}sid=${customer_detatils.sid}`
                    }

                    return window.location.href = urli
                }
            }

            const myStorage = window.localStorage.getItem("customer_detatils");
            if (myStorage) {
                const vehicle = JSON.parse(myStorage);
                setVehicle.setVehicle(vehicle);
                if(vehicle.car_year) {
                    //Manufacturers.getManufacturers({year: vehicle.car_year});
                }
                if(vehicle.manufacturer_id) {
                /*Models.getModelsByManufacturerId({year: vehicle.car_year, manufacturer_id: vehicle.manufacturer_id});*/
                }
                if(vehicle.model_id) {
                /*TrimLevels.getTrimLevelsByYearAndModelId({
                    model_id: vehicle.model_id,
                    year: vehicle.car_year,
                });*/
                }
                if(vehicle.trim_level_id) {
                /*EstimatedValues.getEstimatedValuesByYearManufacturerIdModelIdTrimLevelId(
                    {
                    car_year: vehicle.car_year,
                    manufacturer_id: vehicle.manufacturer_id,
                    model_id: vehicle.model_id,
                    trim_level_id: vehicle.trim_level_id,
                    }
                );*/
                }
            }
        }

        const uid = (e) => {
            switch(e) {
                case 'BikeDetails': return 'fall';
                //case 'DriverDetails': return 'fall_back';
                case 'BikeQuotesDetails': return 'fall_back';
                default: return 'fall';
            }
        }

        const language = computed(() => {
            return store.state.lang.lang
        })

        return {
            go,
            uid,
            vd,
            current,
            language
        }
    },
})
</script>
